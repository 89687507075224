import { createContext, useState, ReactNode, useEffect, useContext, Dispatch, SetStateAction, useCallback } from "react"
import { Song } from "../../utils/types"
import { useBeforeUnload } from "react-router-dom"
// import { songList } from "../../utils/songList"
import { useMqttIsConnected, usePostMqttChangeSong } from "../../mqtt/mqttHandlerContext"
import { useCurrentUser } from "./currentUserContext"
import { userSongList } from "../../utils/songList"

interface CurrentSongContextProps {
    currentSong: Song | undefined
    changeCurrentSong: (song: string) => void
    currentlyPlayingIsExpanded: boolean
    setCurrentlyPlayingIsExpanded: Dispatch<SetStateAction<boolean>>
}

const CurrentSongContext = createContext<CurrentSongContextProps>({
    currentSong: undefined,
    changeCurrentSong: () => {},
    currentlyPlayingIsExpanded: true,
    setCurrentlyPlayingIsExpanded: () => {},
})

const saveToLocalStorage = (song: string) => {
    // Save the current song in local storage for
    // access in next session
    localStorage.setItem("currentSong", song as string)
}

const readLastCurrentSong = () => {
    // Read the current song from local storage to
    // resume last session
    return localStorage.getItem("currentSong")
}

export const CurrentSongProvider = ({ children }: { children: ReactNode }) => {
    const [currentSong, setCurrentSong] = useState<Song | undefined>(undefined)
    const [currentlyPlayingIsExpanded, setCurrentlyPlayingIsExpanded] = useState<boolean>(true)
    const mqttIsConnected = useMqttIsConnected()
    const postMqttChangeSong = usePostMqttChangeSong()
    const currentUser = useCurrentUser()

    const songList = userSongList(currentUser) //currentUser?.songList

    const changeCurrentSong = (songId: string) => {
        // Get song details
        const songObject = songList?.find((songObj) => songObj.id === songId)

        // Set current song
        setCurrentSong(songObject)
    }

    useEffect(() => {
        // Read current song from last session
        const lastSongId = readLastCurrentSong()
        if (lastSongId) {
            // Get song details
            const songObject = songList?.find((songObj) => songObj.id === lastSongId)

            // Set current song
            setCurrentSong(songObject)
        }
    }, [])

    useEffect(() => {
        // Message change to instruments
        if (currentSong && mqttIsConnected) {
            postMqttChangeSong(currentSong.id)
        }
    }, [currentSong, mqttIsConnected])

    // Save to local storage to resume next time
    useBeforeUnload(
        useCallback(() => {
            saveToLocalStorage(currentSong?.id as string)
        }, [currentSong])
    )

    return <CurrentSongContext.Provider value={{ currentSong, changeCurrentSong, currentlyPlayingIsExpanded, setCurrentlyPlayingIsExpanded }}>{children}</CurrentSongContext.Provider>
}

export const useCurrentSong = () => useContext(CurrentSongContext)?.currentSong
export const useChangeCurrentSong = () => useContext(CurrentSongContext)?.changeCurrentSong
export const useCurrentlyPlayingIsExpanded = () => useContext(CurrentSongContext)?.currentlyPlayingIsExpanded
export const useSetCurrentlyPlayingIsExpanded = () => useContext(CurrentSongContext)?.setCurrentlyPlayingIsExpanded
