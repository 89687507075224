import { Song, SONG_IDs, SONG_TYPES, SONG_CATEGORIES } from "./types"
import acoustic from "../images/acoustic.jpg"
import handpan from "../images/handpan.png"
import oboj from "../images/oboy.png"
import deadlymeadly from "../images/deadly-meadly.jpg"
import animals from "../images/animal.jpg"
import percussion from "../images/song-percussion.jpg"
import pop from "../images/song-pop.jpg"
import blinka from "../images/blinka.png"
import car from "../images/car.png"
import farm from "../images/farm.png"
import christmas from "../images/christmas.png"
import water from "../images/water.png"
import human from "../images/human.png"
import oud from "../images/oud.png"
import trollmor from "../images/trollmor.png"
import church from "../images/church.png"
import ohlsson from "../images/ohlsson.png"
import { CurrentUser } from "../hooks/contexts/currentUserContext"

export const userSongList = (currentUser: CurrentUser): Song[] => {
    //TODO: Kulturskolan workaround, remove when songlist is in firebase
    if (currentUser?.customer?.id === "-NoWhbSdpfWvYzLGphFp") {
        return songListKulturskolan
    } else return songList
}

export const songList: Song[] = [
    {
        title: "Demo A",
        description: "Marin Demo A",
        img: acoustic,
        id: "Dyr_Rytmer_A",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
    },
    {
        title: "Demo B",
        description: "Marin Demo B",
        img: acoustic,
        id: "Dyr_Rytmer_B",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
    },
    {
        title: "Akustisk",
        description: "Akustisk låt",
        img: acoustic,
        id: SONG_IDs.ACOUSTIC,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
        hasSections: true,
    },
    {
        title: "Deadly Meadly",
        img: deadlymeadly,
        id: SONG_IDs.DEADLYMEADLY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.DISCO,
        keytone: "E-dur",
        genre: "disco",
        hasSections: true,
    },
    {
        title: "Kristendom",
        description: "Ljud från kyrkan",
        img: church,
        id: SONG_IDs.CHURCH,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.PLACES,
    },
    {
        title: "Jullåt",
        description: "God jul!",
        img: christmas,
        id: SONG_IDs.CHRISTMAS,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.HOLIDAYS,
    },
    {
        title: "Vatten",
        description: "Plums!",
        img: water,
        id: SONG_IDs.WATER,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Slagverk",
        description: "",
        img: percussion,
        id: SONG_IDs.PERCUSSION,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
    },
    {
        title: "Blinka Lilla Stjärna",
        description: "",
        img: blinka,
        id: SONG_IDs.BLINKA,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.BALLADS,
        artist: "Anders Lundström",
    },
    {
        title: "Motorlåda",
        description: "Brum brum!",
        img: car,
        id: SONG_IDs.MOTOR,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Bondgården",
        description: "",
        img: farm,
        id: SONG_IDs.FARM,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.PLACES,
    },
    {
        title: "Trollmor",
        description: "",
        img: trollmor,
        id: SONG_IDs.TROLLMOR,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.BALLADS,
    },
    {
        title: "Metronom",
        description: "Test",
        img: deadlymeadly,
        id: SONG_IDs.METRONOME,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Funki Funk!",
        description: "Det svänger!",
        img: pop,
        id: SONG_IDs.FUNKIFUNK,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.DISCO,
        hasSections: true,
    },
]

//TODO: Kulturskolan workaround, remove when songlist is in firebase
export const songListKulturskolan: Song[] = [
    {
        title: "Avslappning",
        description: "En lung och härlig harmoni",
        img: handpan,
        id: "avslapp",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
        hasSections: false,
        artist: "Niklas Breman",
        genre: "Akustiskt",
    },
    {
        title: "Hele Ninna Olasan",
        description: "En lung och härlig harmoni",
        img: oud,
        id: "hele_ninna",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
        hasSections: false,
        artist: "Hele Ninna Olasan",
        genre: "Akustiskt",
    },
    {
        title: "O boj!",
        description: "",
        img: oboj,
        id: "oboj",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
        hasSections: false,
        artist: "Niklas Breman",
    },
    {
        title: "Akustisk",
        description: "Akustisk låt",
        img: acoustic,
        id: SONG_IDs.ACOUSTIC,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
        hasSections: true,
    },
    {
        title: "Deadly Meadly",
        img: deadlymeadly,
        id: SONG_IDs.DEADLYMEADLY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.DISCO,
        keytone: "E-dur",
        genre: "disco",
        hasSections: true,
    },
    {
        title: "Jullåt",
        description: "God jul!",
        img: christmas,
        id: SONG_IDs.CHRISTMAS,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.HOLIDAYS,
    },
    {
        title: "Slagverk",
        description: "",
        img: percussion,
        id: SONG_IDs.PERCUSSION,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
    },
    {
        title: "Blinka Lilla Stjärna",
        description: "",
        img: blinka,
        id: SONG_IDs.BLINKA,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.BALLADS,
        artist: "Anders Lundström",
    },
    {
        title: "Trollmor",
        description: "",
        img: trollmor,
        id: SONG_IDs.TROLLMOR,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.BALLADS,
    },
    {
        title: "Motorlåda",
        description: "Brum brum!",
        img: car,
        id: SONG_IDs.MOTOR,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Bondgården",
        description: "",
        img: farm,
        id: SONG_IDs.FARM,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.PLACES,
    },
    {
        title: "Funki Funk!",
        description: "Det svänger!",
        img: pop,
        id: SONG_IDs.FUNKIFUNK,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.DISCO,
        hasSections: true,
    },
    {
        title: "Vatten",
        description: "Plums!",
        img: water,
        id: SONG_IDs.WATER,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Människolådan",
        description: "",
        img: human,
        id: SONG_IDs.HUMAN,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Rocka Fett",
        description: "",
        img: animals,
        id: SONG_IDs.ROCKAFETT,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Pop",
        description: "",
        img: pop,
        id: SONG_IDs.POP,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.DISCO,
    },
    {
        title: "Miran",
        description: "",
        img: "",
        id: SONG_IDs.MIRAN,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Oliver",
        description: "",
        img: "",
        id: SONG_IDs.OLIVER,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "Samer",
        description: "",
        img: "",
        id: SONG_IDs.SAMER,
        type: SONG_TYPES.SOUNDBANKS,
        category: SONG_CATEGORIES.SOUNDBANKS.SOUND_EFFECTS,
    },
    {
        title: "50 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.FIFTYBPM,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "60 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.SIXTY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
        hasSections: true,
    },
    {
        title: "70 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.SEVENTY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
        hasSections: true,
    },
    {
        title: "80 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.EIGHTY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
        hasSections: true,
    },
    {
        title: "90 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.NINETHY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
        hasSections: true,
    },
    {
        title: "100 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.HUNDERED,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
        hasSections: true,
    },
    {
        title: "110 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.HUNDEREDTEN,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "120 BPM",
        description: "Komprytm",
        img: "",
        id: SONG_IDs.HUNDEDTWENTY,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "Ohlssons Gård",
        description: "",
        img: ohlsson,
        id: SONG_IDs.OHLSSONS,
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.ACOUSTIC,
    },
    {
        title: "70new",
        description: "Komprytm",
        img: "",
        id: "70new",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "80new",
        description: "Komprytm",
        img: "",
        id: "80new",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "90new",
        description: "Komprytm",
        img: "",
        id: "90new",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "100new",
        description: "Komprytm",
        img: "",
        id: "100new",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
    {
        title: "120new",
        description: "Komprytm",
        img: "",
        id: "120new",
        type: SONG_TYPES.MUSIC,
        category: SONG_CATEGORIES.MUSIC.COMP,
    },
]
