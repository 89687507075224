import { createContext, useState, ReactNode, useEffect, useContext } from "react"
import { User, onAuthStateChanged } from "firebase/auth"
import { auth } from "../../firebase/firebaseConfig"
import { getAllSongsForCustomer, getCustomerDataFromUser, userIsAdmin } from "../../firebase/databaseApi"
import { Instrument, Song } from "../../utils/types"
import { userSongList } from "../../utils/songList"

export type CurrentUser =
    | undefined
    | null
    | {
          auth: User
          customer: null | {
              id: string
              alias: string
              paymentPlan: string
              instruments: Instrument[]
              songPackages: string[]
          }
          songList: Song[]
          admin: boolean
      }

interface CurrentUserContextProps {
    currentUser: CurrentUser | null
    reloadCurrentUser: () => void
}

const CurrentUserContext = createContext<CurrentUserContextProps>({
    currentUser: undefined,
    reloadCurrentUser: () => {},
})

export const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
    const [currentUser, setCurrentUser] = useState<CurrentUser>(undefined)
    const [triggerUserReload, setTriggerUserReload] = useState<boolean>(false)

    const reloadCurrentUser = () => {
        // Trigger the current user context to be reloaded and fetch
        // potentially new data from database
        setTriggerUserReload((prev) => !prev)
    }

    useEffect(() => {
        // Initialize the listener only once when the component is mounted
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("Logged in user:", user.uid)
                const customerData = await getCustomerDataFromUser(user.uid)
                const songList = userSongList(currentUser) //customerData ? await getAllSongsForCustomer(customerData.id) : []
                const isAdmin = await userIsAdmin(user.uid)
                const userObj = {
                    auth: user,
                    customer: customerData,
                    songList: songList,
                    admin: isAdmin,
                }
                setCurrentUser(userObj)
            } else {
                console.log("No user is logged in")
                setCurrentUser(null)
            }
        })

        return () => {
            // Clean up the listener when the component unmounts
            unsubscribe()
        }
    }, [triggerUserReload])

    return <CurrentUserContext.Provider value={{ currentUser, reloadCurrentUser }}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUser = () => useContext(CurrentUserContext)?.currentUser
export const useReloadCurrentUser = () => useContext(CurrentUserContext)?.reloadCurrentUser
