import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react"
import { Client } from "paho-mqtt"
import { MQTT_API } from "../utils/api"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { Instrument } from "../utils/types"

interface MqttHandlerContextProps {
    mqttIsConnected: boolean
    connectedInstruments: Map<string, ConnectedInstrumentInfo>
    postChangeSong: (message: string, instrumentId?: string) => void
    postBackingtrack: (message: string, instrumentId?: string) => void
    postMetronome: (message: string, instrumentId?: string) => void
    postChangeSection: (message: string, instrumentId?: string) => void
    postChangeVolume: (message: string, instrumentId: string) => void
    postChangeMasterVolume: (message: string) => void
}

interface ConnectedInstrumentInfo {
    type: string
    lastOnlineTimestamp: number
}

const MqttHandlerContext = createContext<MqttHandlerContextProps>({
    mqttIsConnected: false,
    connectedInstruments: new Map<string, ConnectedInstrumentInfo>(),
    postChangeSong: () => {},
    postBackingtrack: () => {},
    postMetronome: () => {},
    postChangeSection: () => {},
    postChangeVolume: () => {},
    postChangeMasterVolume: () => {},
})

const generateRandomClientId = () => {
    const randomNumber = Math.floor(Math.random() * 90000) + 10000
    return randomNumber.toString()
}

export const MqttHandlerProvider = ({ children }: { children: ReactNode }) => {
    const currentUser = useCurrentUser()
    const [mqttClient, setMqttClient] = useState<Client | undefined>(undefined)
    const [mqttIsConnected, setMqttIsConnected] = useState<boolean>(false)
    const [connectedInstruments, setConnectedInstruments] = useState<Map<string, ConnectedInstrumentInfo>>(new Map<string, ConnectedInstrumentInfo>())
    const connectedInstrumentsRef = useRef(connectedInstruments)

    const options = {
        host: "c745b068f8a54074bd1f61681a38d9e1.s1.eu.hivemq.cloud",
        port: 8884,
        protocol: "wss",
        clientId: "web" + generateRandomClientId(),
        userName: MQTT_API.CREDITS.USERNAME,
        password: MQTT_API.CREDITS.PASSWORD,
        keepAliveInterval: 30,
    }

    ////// USE EFFECTS //////

    useEffect(() => {
        if (!currentUser?.customer) {
            console.log("No customer connected, skipping MQTT handler setup")
            return
        } else {
            console.log("Customer found, initializing MQTT handler")

            // Create MQTT client
            const client = new Client(options.host, options.port, options.clientId)

            // Configure MQTT client
            configureMqttClient(client)

            // Set an interval to check if instruments have lost connection
            const intervalId = configureIntervalCheckForInstruments()

            // Cleanup when component unmounts
            return () => {
                // Cleanup MQTT client
                if (client.isConnected()) {
                    // Update onConnectionLost to not reconnect
                    client.onConnectionLost = (error) => {
                        setMqttIsConnected(false)
                    }
                    // Disconnect MQTT client
                    client.disconnect()
                    console.log("Disconnected MQTT client on component unmount")
                }

                // Clear the interval
                clearInterval(intervalId)
                console.log("Cleared interval for checking timed out instruments on component unmount")
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (mqttClient) {
            console.log("mqttClient is connected", mqttClient.isConnected())
            setMqttIsConnected(mqttClient.isConnected())

            if (currentUser?.customer?.id) {
                // Subscribe to topics
                const customerId = currentUser?.customer?.id
                mqttClient.subscribe(`${customerId}/${MQTT_API.TOPIC_ACTION.ON_START}/+`)
                mqttClient.subscribe(`${customerId}/${MQTT_API.TOPIC_ACTION.HEARTBEAT}/+`)
            }
        } else {
            setMqttIsConnected(false)
        }
    }, [mqttClient])

    useEffect(() => {
        // Needed for use in interval for checking timed out instruments
        connectedInstrumentsRef.current = connectedInstruments
    }, [connectedInstruments])

    ////// PRIVATE METHODS //////

    const configureMqttClient = (client: Client) => {
        // Set on conneciton lost
        client.onConnectionLost = (error) => {
            console.error(`MQTT connection lost: ${error.errorMessage}`)
            setMqttIsConnected(false)
            setMqttClient(undefined)

            // Reconnect
            connectMqttClient(client)
        }

        // Initial MQTT client connect
        connectMqttClient(client)

        // Set on message arrived
        client.onMessageArrived = (message) => {
            const customerId = currentUser?.customer?.id

            if (message.destinationName.includes(`${customerId}/${MQTT_API.TOPIC_ACTION.ON_START}`) || message.destinationName.includes(`${customerId}/${MQTT_API.TOPIC_ACTION.HEARTBEAT}`)) {
                // An instrument sent on-start message or heartbeat message

                const instrumentId = message.payloadString

                // Update connectedInstruments to include the new instrument id,
                // or update timestamp if this instrument id already exists in connectedInstruments
                setConnectedInstruments((prevMap) => {
                    const updatedMap = new Map(prevMap)
                    const prevValue: ConnectedInstrumentInfo | undefined = updatedMap.get(instrumentId)
                    if (prevValue) {
                        updatedMap.set(instrumentId, { ...prevValue, lastOnlineTimestamp: Date.now() })
                    } else {
                        const instrumentObj: Instrument | undefined = currentUser?.customer?.instruments.find((obj: Instrument) => obj.deviceId === instrumentId)
                        if (instrumentObj) {
                            updatedMap.set(instrumentId, { type: instrumentObj.type, lastOnlineTimestamp: Date.now() })
                        } else {
                            // Handle unknown instrument trying to connect
                            console.error(`Unknown instrument: got a message on ${message.destinationName} for an instrument with id ${instrumentId}`)
                        }
                    }
                    return updatedMap
                })
            }
        }
    }

    const connectMqttClient = (client: Client) => {
        client.connect({
            useSSL: true,
            onSuccess: () => {
                console.log("Successfully connected MQTT client")
                setMqttClient(client)
            },
            onFailure: (error) => {
                console.log("Connection failed:", error.errorMessage)
                // Handle what should happen if connection fail
            },
            userName: options.userName,
            password: options.password,
            keepAliveInterval: options.keepAliveInterval,
        })
    }

    const configureIntervalCheckForInstruments = () => {
        return setInterval(() => {
            const timeoutDuration = 10000
            const timedOutInstruments: string[] = []
            const currentTime = Date.now()

            // Collect all instruments that have timed out (lost connection)
            connectedInstrumentsRef.current.forEach((instrumentInfo: ConnectedInstrumentInfo, instrumentId: string) => {
                if (currentTime - instrumentInfo.lastOnlineTimestamp > timeoutDuration) {
                    timedOutInstruments.push(instrumentId)
                }
            })

            // Remove timeout instruments from connectedInstruments
            setConnectedInstruments((prevMap) => {
                const updatedMap = new Map(prevMap)
                timedOutInstruments.forEach((instrumentId: string) => {
                    updatedMap.delete(instrumentId)
                })
                return updatedMap
            })
        }, 5000)
    }

    ////// METHODS FOR POSTING ON TOPICS //////

    const postChangeSong = (message: string, instrumentId?: string) => {
        const customerId = currentUser?.customer?.id

        const payLoad = {
            message: message,
            timeStamp: Date.now(),
        }

        if (mqttClient?.isConnected()) {
            if (instrumentId) {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.SONG}/${instrumentId}`, JSON.stringify(payLoad), 1, false)
            } else {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.SONG}`, JSON.stringify(payLoad), 1, false)
            }
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    // export function handleChangeSong(message: SONGS) {
    // 	let changeSongTopic = MQTT_API.INSTRUMENTS.ALL + MQTT_API.TOPIC.SONG
    // 	const payLoad = {
    // 	  message: message,
    // 	  timeStamp: Date.now(),
    // 	}
    // 	mqttClient?.send(changeSongTopic, JSON.stringify(payLoad), 1, false)
    //   }

    const postBackingtrack = (message: string, instrumentId?: string) => {
        const customerId = currentUser?.customer?.id
        if (mqttClient?.isConnected()) {
            if (instrumentId) {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.BACKINGTRACK}/${instrumentId}`, message, 1, false)
            } else {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.BACKINGTRACK}`, message, 1, false)
            }
        } else {
            console.error("MQTT client not connected: unable to send backingtrack message")
        }
    }

    const postMetronome = (message: string, instrumentId?: string) => {
        const customerId = currentUser?.customer?.id
        if (mqttClient?.isConnected()) {
            if (instrumentId) {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.METRONOME}/${instrumentId}`, message, 1, false)
            } else {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.METRONOME}`, message, 1, false)
            }
        } else {
            console.error("MQTT client not connected: unable to send metronome message")
        }
    }

    const postChangeSection = (message: string, instrumentId?: string) => {
        const customerId = currentUser?.customer?.id
        if (mqttClient?.isConnected()) {
            if (instrumentId) {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.SECTION}/${instrumentId}`, message, 1, false)
            } else {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.SECTION}`, message, 1, false)
            }
        } else {
            console.error("MQTT client not connected: unable to send change section message")
        }
    }

    const postChangeVolume = (message: string, instrumentId?: string) => {
        const customerId = currentUser?.customer?.id
        if (mqttClient?.isConnected()) {
            if (instrumentId) {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.VOLUME}/${instrumentId}`, message, 0, false)
            } else {
                mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.VOLUME}`, message, 0, false)
            }
        } else {
            console.error("MQTT client not connected: unable to send change volume message")
        }
    }

    const postChangeMasterVolume = (message: string) => {
        const customerId = currentUser?.customer?.id
        if (mqttClient?.isConnected()) {
            mqttClient.send(`${customerId}/${MQTT_API.TOPIC_ACTION.MASTER_VOLUME}`, message, 0, false)
        } else {
            console.error("MQTT client not connected: unable to send change master volume message")
        }
    }

    return (
        <MqttHandlerContext.Provider
            value={{
                mqttIsConnected,
                connectedInstruments,
                postChangeSong,
                postBackingtrack,
                postMetronome,
                postChangeSection,
                postChangeVolume,
                postChangeMasterVolume,
            }}
        >
            {children}
        </MqttHandlerContext.Provider>
    )
}

export const useConnectedInstruments = () => useContext(MqttHandlerContext).connectedInstruments
export const useMqttIsConnected = () => useContext(MqttHandlerContext).mqttIsConnected
export const usePostMqttChangeSong = () => useContext(MqttHandlerContext).postChangeSong
export const usePostMqttBackingtrack = () => useContext(MqttHandlerContext).postBackingtrack
export const usePostMqttMetronome = () => useContext(MqttHandlerContext).postMetronome
export const usePostMqttChangeSection = () => useContext(MqttHandlerContext).postChangeSection
export const usePostMqttChangeVolume = () => useContext(MqttHandlerContext).postChangeVolume
export const usePostMqttChangeMasterVolume = () => useContext(MqttHandlerContext).postChangeMasterVolume
